@import url('https://fonts.googleapis.com/css2?family=Fragment+Mono:ital@0;1&display=swap');

body {
  margin: 0;
  font-family: 'Fragment Mono', monospace !important;
  background-color: black !important;
}

.bg-dark {
  background-color: black !important;
}

.text-bg-dark {
  background-color: black !important;
}

.form-control.text-bg-dark {
  background-color: black !important;
}

.form-check-input.text-bg-dark {
  background-color: black !important;
}

.card.text-bg-dark {
  background-color: black !important;
}

mark {
  background-color: white !important;
}

.pointer {
  cursor: pointer !important;
}

/* Backdrop for modals */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.backdrop > .card {
  display: block;
  position: absolute;
  width: 40%; 
  max-height: 80%;
  z-index: 2;
}

.backdrop > .buttons {
  display: block;
  position: absolute;
  width: 10%;
  margin-left: 55vw;
  max-height: 80%;
  z-index: 2;
}