.navbar-brand.active {
  color: #f8f9fa !important;
  text-decoration: underline;
}

.nav-link.active {
  color: #f8f9fa !important;
  text-decoration: underline;
}

.navbar.bg-dark {
  background-color: rgba(0,0,0,0.5) !important;
  backdrop-filter: blur(10px);
}