.badge-btn {
  cursor: pointer;
}

.badge-btn:hover {
  opacity: 0.7;
}

.btn-xs {
  font-size: 0.6em !important;
  padding:  2px 6px 1px 6px !important;
  margin-top: -3px !important;
}